






















import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    avatar: () => import('@/modules/community/common/components/avatar.vue'),
    'follow-button': () => import('./follow-button.vue'),
  },
})
export default class FollowerItem extends Vue {
  @Prop() item?: any
  @Prop() borderRadius?: 'top' | 'bottom'

  walletStore = walletStore
}
